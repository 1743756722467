<template>
  <div class="recommended-products">
    <RecommendedProductCard
      v-for="product in products"
      :key="product.id"
      :product="product"
    />
  </div>
</template>

<script setup>
import RecommendedProductCard from "~/modules/shared/productCard/RecommendedProductCard.vue";
import { useAsyncAPI } from "~/uses/useMyFetch";
import { Product } from "~/models/product.model";
import { Category } from "~/models/category.model";

const props = defineProps({
  category: { type: Category, required: true },
});

const products = ref([]);

await useAsyncAPI("/catalog/product/list/all", {
  params: {
    pageSize: 10,
    pageNum: 1,
    categoryId: props.category.id,
  },
}).then(({ data }) => {
  products.value = data.items.map((dto) => new Product(dto));
});
</script>

<style scoped lang="scss">
.recommended-products {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
}
</style>
